// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               v5.26.1
// source: go.chromium.org/luci/tree_status/proto/v1/trees.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "luci.tree_status.v1";

export interface QueryTreesRequest {
  /** The LUCI project to query tree name. */
  readonly project: string;
}

export interface QueryTreesResponse {
  /**
   * List of trees attached to the project.
   * If there are more than 1 tree attached to a project, the results
   * will be sorted ascendingly based on tree name.
   */
  readonly trees: readonly Tree[];
}

export interface Tree {
  /** Name of the tree, in format "trees/{tree_id}". */
  readonly name: string;
}

function createBaseQueryTreesRequest(): QueryTreesRequest {
  return { project: "" };
}

export const QueryTreesRequest = {
  encode(message: QueryTreesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryTreesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTreesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTreesRequest {
    return { project: isSet(object.project) ? globalThis.String(object.project) : "" };
  },

  toJSON(message: QueryTreesRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTreesRequest>): QueryTreesRequest {
    return QueryTreesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTreesRequest>): QueryTreesRequest {
    const message = createBaseQueryTreesRequest() as any;
    message.project = object.project ?? "";
    return message;
  },
};

function createBaseQueryTreesResponse(): QueryTreesResponse {
  return { trees: [] };
}

export const QueryTreesResponse = {
  encode(message: QueryTreesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.trees) {
      Tree.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryTreesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTreesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.trees.push(Tree.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTreesResponse {
    return { trees: globalThis.Array.isArray(object?.trees) ? object.trees.map((e: any) => Tree.fromJSON(e)) : [] };
  },

  toJSON(message: QueryTreesResponse): unknown {
    const obj: any = {};
    if (message.trees?.length) {
      obj.trees = message.trees.map((e) => Tree.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTreesResponse>): QueryTreesResponse {
    return QueryTreesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTreesResponse>): QueryTreesResponse {
    const message = createBaseQueryTreesResponse() as any;
    message.trees = object.trees?.map((e) => Tree.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTree(): Tree {
  return { name: "" };
}

export const Tree = {
  encode(message: Tree, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tree {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTree() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tree {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: Tree): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<Tree>): Tree {
    return Tree.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Tree>): Tree {
    const message = createBaseTree() as any;
    message.name = object.name ?? "";
    return message;
  },
};

export interface Trees {
  /** Query tree for a LUCI project. */
  QueryTrees(request: QueryTreesRequest): Promise<QueryTreesResponse>;
}

export const TreesServiceName = "luci.tree_status.v1.Trees";
export class TreesClientImpl implements Trees {
  static readonly DEFAULT_SERVICE = TreesServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || TreesServiceName;
    this.rpc = rpc;
    this.QueryTrees = this.QueryTrees.bind(this);
  }
  QueryTrees(request: QueryTreesRequest): Promise<QueryTreesResponse> {
    const data = QueryTreesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTrees", data);
    return promise.then((data) => QueryTreesResponse.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
